import Button from 'components/Button/Button';
import ChevronLeft from 'components/Icon/assets/ChevronLeft';
import ChevronRight from 'components/Icon/assets/ChevronRight';
import React from 'react';
import { NavigateAction, ToolbarProps, View } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { CalendarEvent } from '../types';

// Define the prop types for CustomToolbar
// Extend the ToolbarProps and use the View type for views

interface CustomToolbarProps extends ToolbarProps<CalendarEvent, object> {
  label: string;
  onNavigate: (navigate: NavigateAction, date?: Date) => void; // Match the expected type
  views: View[]; // Correctly type views as an array of View objects
  view: View; // Correctly type view as a View object
  onView: (view: View) => void; // Correctly type the onView function
}
export const CustomToolbar: React.FC<CustomToolbarProps> = ({
  label,
  onNavigate,
  views,
  view,
  onView,
}: CustomToolbarProps) => {
  const { t } = useTranslation();

  // Define custom titles for views
  const customViewTitles = [
    t('Calendar.monthTitle'),
    t('Calendar.weekTitle'),
    t('Calendar.dayTitle'),
  ];

  // Slice the views array if needed
  const newViews = views.slice(0, -1); // Remove last view (if necessary)

  return (
    <div className="rbc-toolbar mb-7 flex flex-wrap justify-center text-base items-center gap-y-4">
      {/* Navigation Buttons */}
      <div className="rbc-nav-buttons">
        <button className="prev group" onClick={() => onNavigate('PREV')}>
          <span className="calendar-custom-toolbar" />
          <ChevronLeft className="w-8 h-8 stroke-2" />
        </button>
        <button className="next group" onClick={() => onNavigate('NEXT')}>
          <ChevronRight className="w-8 h-8 stroke-2" />
        </button>
        <button className="next group" onClick={() => onNavigate('TODAY')}>
          <span>{t('Calendar.todayTitle')}</span>
        </button>
      </div>

      {/* Label */}
      <span className="rbc-toolbar-label flex-[1_0_0%] text-center font-semibold text-dark text-xl leading-6">
        {label}
      </span>

      {/* View Buttons */}
      <div className="flex flex-wrap gap-2">
        <div className="rbc-btn-group view-group flex items-center">
          {newViews.map((viewData, index) => (
            <Button
              key={viewData}
              className={`rbc-btn ${view === viewData ? 'rbc-active' : ''}`}
              onClickHandler={() => onView(viewData)} // Pass the correct View type
            >
              {customViewTitles[index]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
