import PageHeader from 'components/PageHeader';
import ReactCalendar from 'components/ReactCalendar';
import { CalendarEvent } from 'components/ReactCalendar/types';
import TabComponent from 'components/Tabs';
import {
  addDays,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
  subDays,
} from 'date-fns';
import { useAxiosGet } from 'hooks/useAxios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Calendar = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentTab = urlParams.get('calendar') ?? 'Courses';
  const [activeTab, setActiveTab] = useState(currentTab ?? 'Courses');
  const [searchParams, setSearchParams] = useSearchParams();
  const [getEvents] = useAxiosGet();
  const currentDate = new Date();
  const currentMonthStart = startOfMonth(currentDate);
  const currentMonthEnd = endOfMonth(currentDate);
  const visibleRangeStart = subDays(currentMonthStart, 6);
  const visibleRangeEnd = addDays(currentMonthEnd, 6);
  const [events, setEvents] = useState();
  const [filterValue, setFilterValue] = useState<{
    start_date?: string | Date;
    end_date?: string | Date;
  }>({
    start_date: format(visibleRangeStart, 'yyyy-MM-dd'),
    end_date: format(visibleRangeEnd, 'yyyy-MM-dd'),
  });

  const fetchEvents = async () => {
    const AllEvents = await getEvents(`/teacher-availabilities`, {
      params: {
        startDate: filterValue.start_date,
        endDate: filterValue.end_date,
        view: true,
      },
    });
    const eventData = AllEvents.data?.data?.map(
      (data: { start_time: string; end_time: string }, index: number) => {
        return {
          start: data?.start_time ? parseISO(data?.start_time) : null,
          end: data?.end_time ? parseISO(data?.end_time) : null,
          title: `Title ${index + 1}`,
          ...data,
        };
      }
    );
    setEvents(eventData);
  };

  useEffect(() => {
    if (activeTab === currentTab) {
      fetchEvents();
    }
  }, [filterValue, activeTab]);

  return (
    <div>
      <PageHeader title={t('Calendar.title')} />
      <TabComponent
        current={activeTab}
        onTabChange={(status: string) => {
          setActiveTab(status);
          searchParams.set('calendar', status);
          setSearchParams(searchParams);
        }}
      >
        <TabComponent.Tab title="Courses" uniqueKey="Courses" />
        <TabComponent.Tab title="Teachers" uniqueKey="Teachers" />
      </TabComponent>
      <div>
        <ReactCalendar
          setFilterRange={setFilterValue}
          events={events as unknown as CalendarEvent[]}
        />
      </div>
    </div>
  );
};

export default Calendar;
