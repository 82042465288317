import { format, getDay, parse, startOfWeek } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { ComponentType, useRef, useState } from 'react';
import { Calendar, dateFnsLocalizer, ToolbarProps, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CustomToolbar } from './Components/CustomToolbar';
import { EventPopover } from './Components/EventPopover';
import { CalendarEvent, ReactCalendarProps } from './types';

import { CustomEvent } from './Components/CustomEvent';

const locales = {
  'en-US': enUS,
  es,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ReactCalendar = ({ setFilterRange, events }: ReactCalendarProps) => {
  const [currentView, setCurrentView] = useState('month');
  const [dayEvents, setDayEvents] = useState<CalendarEvent[]>([]);
  const [showPopOver, setShowPopOversOpen] = useState(false);
  const eventElement = useRef<HTMLElement | any>(null);
  // Close the popover
  const handleClosePopover = () => {
    setShowPopOversOpen(false);
  };

  // Show all events for the selected date range when "Show More" is clicked
  const showPopUpData = (events: CalendarEvent[]) => {
    setDayEvents(events);
    setShowPopOversOpen(true);
  };
  // Custom Show More Button
  const CustomShowMore = ({ events }: any) => {
    return (
      <button
        ref={eventElement} // Attach the ref to the button
        onClick={() => showPopUpData(events)} // Call the function to show events
        className="rbc-show-more" // You can add your own styles here
      >
        SHOW mORE
      </button>
    );
  };
  return (
    <div style={{ position: 'relative', height: '900px' }}>
      <Calendar
        onView={(e) => {
          setCurrentView(e);
        }}
        className={
          currentView === 'month'
            ? 'admin-month-view'
            : currentView === 'week'
              ? 'admin-week-view'
              : currentView === 'day'
                ? 'admin-day-view'
                : ''
        }
        defaultView={currentView as View}
        localizer={localizer}
        events={events as CalendarEvent[]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '900px' }}
        onRangeChange={(range) => {
          if (Array.isArray(range)) {
            setFilterRange?.({
              start_date: range[0].toISOString(),
              end_date: range[range.length - 1].toISOString(),
            });
          } else {
            setFilterRange?.({
              start_date: range?.start.toISOString(),
              end_date: range?.end.toISOString(),
            });
          }
        }}
        components={{
          toolbar: CustomToolbar as unknown as
            | ComponentType<ToolbarProps<CalendarEvent, object>>
            | undefined,
          event: (eventProps: any) => {
            return <CustomEvent event={eventProps as any} />;
          },
          showMore: CustomShowMore as any,
        }}
        formats={{
          dayFormat: (date, culture) => {
            return format(date, 'EEEE dd', {
              locale: locales[culture as keyof typeof locales],
            });
          },
          weekdayFormat: (date, culture) => {
            return format(date, 'EEEE', {
              locale: locales[culture as keyof typeof locales],
            });
          },
          dayRangeHeaderFormat: ({ start, end }, culture) => {
            const locale = locales[culture as keyof typeof locales] || enUS;
            const formattedStart = format(start, 'MMMM d', { locale });
            const formattedEnd = format(end, 'd, yyyy', { locale });
            return `${formattedStart} – ${formattedEnd}`;
          },
          dayHeaderFormat: (date, culture) => {
            return format(date, 'MMMM dd, yyyy', {
              locale: locales[culture as keyof typeof locales],
            });
          },
          monthHeaderFormat: (date, culture) => {
            return format(date, 'MMMM, yyyy', {
              locale: locales[culture as keyof typeof locales],
            });
          },
          eventTimeRangeFormat: () => {
            return '';
          },
        }}
        onShowMore={(events) => {
          showPopUpData(events); // Trigger popover to show all events
        }}
        doShowMoreDrillDown={false}
      />
      <EventPopover
        isOpen={showPopOver}
        onClose={handleClosePopover}
        dayEvents={dayEvents} // Pass all events to popover
        referenceElement={eventElement.current}
      />
    </div>
  );
};

export default ReactCalendar;
