import Image from 'components/Image';
import { format, parseISO } from 'date-fns';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useRef } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '../hooks/useClickOutside';
import { CalendarEvent, GroupedEvents } from '../types';

interface EventPopoverProps {
  referenceElement: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  dayEvents: CalendarEvent[];
}

export const EventPopover: React.FC<EventPopoverProps> = ({
  referenceElement,
  isOpen,
  onClose,
  dayEvents,
}) => {
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(
    null
  );
  const popperRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(popperRef, onClose, { current: referenceElement });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  if (!isOpen) return null;
  const groupedEvents = _.groupBy(dayEvents, 'user_id') as unknown as GroupedEvents;

  return (
    <div
      ref={(el) => {
        setPopperElement(el);
        if (popperRef) {
          popperRef.current = el;
        }
      }}
      style={styles.popper}
      {...attributes.popper}
      className="month-event-popover"
    >
      <h3 className="">
        {t('Calendar.modal.popupText')} <span>(29 May, 2024)</span>
      </h3>

      {!_.isEmpty(groupedEvents) &&
        Object.entries(groupedEvents).map(([userId, events]) => (
          <div key={userId} className="teacher-profile-item">
            {/* User Information (Render only once for each user) */}
            <div className="teacher-profile-wrap">
              <div className="teacher-profile-image">
                <Image
                  imgClassName="w-full h-full rounded-full"
                  src={events[0]?.user?.profile_image}
                />
              </div>
              <span className="teacher-profile-text">
                {events[0]?.user?.full_name}
              </span>
            </div>

            <div className="teacher-profile-slot-list">
              {events.map((event) => (
                <div key={event.id} className="teacher-profile-slot-item">
                  {format(parseISO(event.start_time), 'h:mm a')} -
                  {format(parseISO(event.end_time), 'h:mm a')}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};
